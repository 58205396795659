import React from 'react';
import './About.css';

import { Heading, Button } from '../../components';
import { education, experience, skills } from '../../constants';
import resume from '../../assets/resume.pdf';
import { FaGraduationCap, FaBriefcase } from 'react-icons/fa';
import CountUp from 'react-countup';



const About = () => {  

  const size = 6;
  const skill = skills.slice(0, size);
  const skill1 = skill.slice(0, 3);
  const skill2 = skill.slice(3, size);

  return (
    <section className='about' id='about'>

      <Heading title={'about me'} span={'know about me'} />

      {/*---------- Personal Information ----------*/}
      <div className='box-container'>

        <h5>Personal Info</h5>

        <div className='text'>

          <h2>My name is Busola.</h2> 
          <p>Experienced Senior Program/Project Manager, Agile Coach, Scrum Master and Change Management expert. 
          with over 15 years’ experience in the Financial Industry, Consulting, Healthcare, Telecommunication, Education, Technology and Public sectors.
              </p>
          <div className='about-info'>
            <h4>Gender: <span>Female</span></h4>
            <h4>Language: <span>English</span></h4>
            <h4>Expertise: <span>Project Manager, Scrum Master, Agile Coach, Business Analyst, Organizational Change Manager </span></h4>
            <h4>Certifications: <span>PMP, COBIT, ITIL, CSP, A-CSM, CSM, Microsoft Certified: Azure AI Fundamentals,  Certified Change Management Professional- PROSCI, Scrum Foundation Educator</span></h4>
          </div>
          {/* Button to download the resume with the custom filename */}
          <Button title={'download CV'} link={resume} download={true} downloadName="resume.pdf"/>

        </div> 

        <div class="counting"> 

          <div class="box">
            <CountUp class="count" end={15} duration={3} suffix={'+'} />
            <h3>Years of Experience</h3>
          </div>
          
          <div class="box">
              <CountUp class="count" end={50} duration={3} suffix={'+'} />
              <h3>Projects Delivered</h3>
          </div>

          <div class="box">
          <CountUp class="count" end={10} duration={3} suffix={'+'} />
              <h3>Happy Clients</h3>
          </div>
                      
          <div class="box">
          <CountUp class="count" end={1} duration={3} suffix={''} />
              <h3>Awards Won</h3>
          </div>
      
        </div>

      </div>

      {/*---------- Skills ----------*/}
      <div className='skill-container'>
        <h5>My Skills</h5>

        <div className='skills'> 

          <div className='skill-item item1'>            
          {
              skill1.map((skill) => {
                return(
                  <div className='box' key={skill.id}>
                    <h3>{skill.title} <span>{skill.progress}</span></h3>
                    <div className='progress-bar'> <span style={{width:skill.progress}}></span> </div>
                  </div>
                )
              })
            } 
          </div>

          <div class="skill-item item2">
          {
              skill2.map((skill) => {
                return(
                  <div className='box' key={skill.id}>
                    <h3>{skill.title} <span>{skill.progress}</span></h3>
                    <div className='progress-bar'> <span style={{width:skill.progress}}></span> </div>
                  </div>
                )
              })
            }
          </div>

        </div>

      </div>

      {/*---------- Resume ----------*/}
      <div className='resume'>
        <h5>My Resume</h5>

        <div className='resume-container'>
          <div className='education'>

            <h6><span>Education</span></h6>
            {
              education.map((item) => {
                return(
                  <div class="education-item" key={item.id}>
                    <FaGraduationCap className='icon' />
                    <div class="content">
                        <span>{item.duration}</span>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className='experience'>

            <h6><span>Experience</span></h6>

            {
              experience.map((item) => {
                return(
                  <div class="experience-item" key={item.id}>
                    <FaBriefcase className='icon' />
                    <div class="content">
                        <span>{item.duration}</span>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </section>
    
  )
}

export default About;